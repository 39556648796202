<template>
	<ZyroDrawer class="ecommerce-store-drawer">
		<p class="z-h5 ecommerce-store-drawer__title">
			{{ $t('builder.addEcommerceStoreDrawer.title') }}
		</p>
		<div class="ecommerce-store-drawer__content">
			<img
				class="ecommerce-store-drawer__store-image"
				src="@/assets/images/store-sample.png"
			>
			<p class="ecommerce-store-drawer__page-title z-h5">
				{{ $t('builder.addEcommerceStoreDrawer.contentTitle') }}
			</p>
			<p class="ecommerce-store-drawer__page-subtitle z-body-small z-body-small--light">
				{{ $t('builder.addEcommerceStoreDrawer.contentSubtitle') }}
			</p>
			<ZyroButton
				v-qa="'drawer-addecommerce-addecommercestore-btn'"
				theme="primary"
				@click="handleAddEcommercePage"
			>
				{{ $t('builder.addEcommerceStoreDrawer.button') }}
			</ZyroButton>
		</div>
	</ZyroDrawer>
</template>

<script>

import { mapActions } from 'vuex';

import { ECOMMERCE_PAGE_TYPE } from '@/constants';
import {
	CLOSE_DRAWER,
	mapActionsGui,
} from '@/store/builder/gui';

export default {
	setup() {
		return { ECOMMERCE_PAGE_TYPE };
	},
	methods: {
		...mapActions('pages', ['addPage']),
		...mapActionsGui({ closeDrawer: CLOSE_DRAWER }),
		handleAddEcommercePage() {
			this.closeDrawer();
			this.addPage({ type: ECOMMERCE_PAGE_TYPE });
		},
	},
};
</script>

<style lang="scss" scoped>
.ecommerce-store-drawer {
	padding: 24px;

	&__content {
		text-align: center;
	}

	&__title {
		margin-bottom: 64px;
	}

	&__store-image {
		width: 206px;
		height: 181px;
		margin-bottom: 32px;
		object-fit: cover;
	}

	&__page-title {
		margin-bottom: 8px;
	}

	&__page-subtitle {
		margin-bottom: 24px;
	}
}
</style>
